import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"
import { Box } from "@mui/material"

const ActiveThumbnail = ({nodeId, pageNumber, storeId}: {nodeId: string, pageNumber: number, storeId: string}) => {
  const { pdfMetaStore, podStore, uiStore } = useStore()

  const activePage = pdfMetaStore.getPageInView(storeId)
  const isActive = (activePage === pageNumber) ? true : false
  const isViewd = podStore.getView('pdfPage', nodeId, pageNumber).dSeen>uiStore.readingTimer ? true : false

  return (
    <Box sx={{
      alignItems: "center",
      backgroundColor: isActive ? "#7a78d480" : "",
      display: "grid",
      gridTemplateColumns: "auto min-content",
      justifyItems: "center",
      fontStyle: "oblique",
      fontSize: "12px",
    }}>
      <Box>
        {pageNumber}
      </Box>
      <ViewMark isViewd={isViewd} />
    </Box>
  )
}

export default observer(ActiveThumbnail)


const ViewMark = ({isViewd}: {isViewd: boolean}) => {
  const { uiStore } = useStore()
  const markSize = (uiStore.deviceWidth === 'tiny' || uiStore.deviceWidth === 'smaller' || uiStore.deviceWidth === 'small') ? "5px" : "8px"

  if(isViewd) return (
    <Box sx= {{
      backgroundColor: "#0a0",
      height: markSize,
      margin: "1px",
      width: markSize
    }}/>
  )
  return (
    <Box sx= {{
      border: "1px solid #0a0",
      height: markSize,
      margin: "1px",
      width: markSize
    }}/>
  )
}
