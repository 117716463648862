import React, { useState } from "react"
import { useStore } from "../../../contexts/store"
import { observer } from "mobx-react-lite"

const ThumbnailScroll = ({storeId, thumbListRef, thumbnailHeight, thumbnailUrl}: {storeId: string, thumbListRef: any, thumbnailHeight: number, thumbnailUrl: string}) => {
  const { pdfMetaStore } = useStore()
  const [currentPage, setCurrentPage] = useState(1)
  const activePage = pdfMetaStore.getPageInView(storeId)

  // active site has changed
  if(activePage !== currentPage) {
    // update component state with next active page
    setCurrentPage(activePage)
    // if active page is currently rendered: test if in viewport
    // else jump to page
    const activeThumb = document.getElementById(`${thumbnailUrl}/${activePage}`)
    if(activeThumb && thumbnailHeight) {
      const top = activeThumb.getBoundingClientRect()?.top
      let appHeader = document.getElementById("app-header")
      const headerHeight = appHeader ? appHeader.offsetHeight : 64
      const innerHeight = window ? window.innerHeight : null
      // scroll thumbnail into the field of view when it is above the field of view
      if(top && (top - (thumbnailHeight/2)) < 0) {
        thumbListRef.current.scrollToItem(activePage-1, "start")
      }
      // scroll thumbnail into the field of view when it is below the field of view
      if(top && innerHeight && (top + (thumbnailHeight)) > (innerHeight - headerHeight)) {
        thumbListRef.current.scrollToItem(activePage-1, "end")
      }

    } else {
      if(thumbListRef && thumbListRef.current)
      thumbListRef.current.scrollToItem(activePage-1, "center")
    }


  }

  return (
    <></>
  )
}

export default observer(ThumbnailScroll)
