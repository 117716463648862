import { t } from 'i18next'
import { navigate } from 'wouter/use-location'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useStore } from "../../contexts/store"
import { InteractionType } from '../../shared/src/types/Interaction'
import { SearchItemType } from '../../stores/podStore'
import { useParams } from '../../helper/Helper'
import InteractionIcon from './InteractionIcon'
import UserInfoAvatar from './UserInfoAvatar'
import TimeFromNow from './TimeFromNow'
import Highlighter from "react-highlight-words"
import SearchFilter from './SearchFilter'
import { tokenizeRegex } from '../../shared/src/validation/validationConstantsString'

import { Button, OutlinedInput, Dialog, DialogContent, DialogTitle, Box, debounce, IconButton, LinearProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import ShortTextIcon from '@mui/icons-material/ShortText'
import FilterIcon from '@mui/icons-material/FilterAltOutlined'


const Search = () => {
  const { uiStore, podStore } = useStore()
  const params = useParams()
  const maxItemsInViewInit = 15
  const [maxItemsInView, setMaxItemsInView] = useState(maxItemsInViewInit)
  const open = uiStore.searchIsOpen
  const deviceWidth = uiStore.deviceWidth
  const isFullscreenMode = deviceWidth === "small" || deviceWidth === "smaller" || deviceWidth === "tiny"
  const hasSearchInstance = podStore.hasSearchInstance
  // search term and its hits
  const lastSearchTerm = uiStore.lastSearchTerm
  let searchResult = uiStore.searchResult

  // trim: remove search results whose terms are not contained within one text window
  searchResult = searchResult.filter(item => {
    const itemText = item.text ? item.text.join(' ').toLowerCase() : ""
    const itemLabel = item.label ? item.label.join(' ').toLowerCase() : ""
    if(hasValidWordWindow(itemText, lastSearchTerm) || hasValidWordWindow(itemLabel, lastSearchTerm)) return true
    return false
  })


  // filtered search list
  let filteredSearchResult = searchResult

  // if requested, filter for exact search result
  const exactSearchFilter = uiStore.exactSearchFilter
  if(exactSearchFilter) {
    filteredSearchResult = filteredSearchResult.filter(item => {
      if(item.text && item.text.join(' ').toLowerCase().includes(lastSearchTerm.toLowerCase())) return true
      if(item.label && item.label.join(' ').toLowerCase().includes(lastSearchTerm.toLowerCase())) return true
      return false
    })
  }

  // filter search results by media type and nodeId
  const mediaSearchFilter = uiStore.mediaSearchFilter
  const nodeSearchFilter = uiStore.nodeSearchFilter
  filteredSearchResult = filteredSearchResult.filter(item => {
    if(mediaSearchFilter.includes(item.type)) return false
    if(item.type !== 'message' && item.nodeId && nodeSearchFilter.includes(item.nodeId)) return false
    return true
  })

  const pod = podStore.pod
  if(!pod) return null

  const toggleOpenSearch = () => {
    // update search dict, when search is opened
    if(!open) {
      podStore.updateSearchInstance()
    }
    uiStore.setSearchIsOpen(!open)
  }

  function inputChange() {
    // reset amount of shown items for each new search
    setMaxItemsInView(maxItemsInViewInit)
  }

  const handleClose = () => {
    uiStore.setSearchIsOpen(false)
  }

  const showMoreItems = () => {
    setMaxItemsInView(maxItemsInView+maxItemsInViewInit)
  }

  const navigateToUrl = (url: string, nodeId: string|null) => {
    if(url) {
      navigate(url)
      handleClose()
      // if the pdf has already been loaded,
      // the viewer must be reloaded to make scrolling work
      if(params.pdfId === nodeId) {
        uiStore.forceViewerUpdate()
      }
    }
  }

  const PageItem = ({item}: {item: SearchItemType}) => {
    if(!item.nodeId || item.pageNumber === null) return null
    const pdfPage = pod.getPdfPage(item.nodeId, item.pageNumber)
    if(!pdfPage) return null

    function navigateToPage() {
      if(pod && item.pageNumber !== null) {
        // inform viewer about the scroll
        uiStore.setScrollToPage(item.pageNumber)
        // navigate with url
        const url = `/pod/${pod.podId}/pdf/${item.nodeId}`
        navigateToUrl(url, item.nodeId)
      }
    }

    return(
      <Box sx={{
        borderBottom: "1px solid #ebebeb",
        display: "grid",
        gridTemplateColumns: "60px auto"
      }}>
        {/* icon */}
        <Box sx={{padding: "5px"}}>
          <Box sx={{
              alignContent: "center",
              background: "#467993",
              borderRadius: "5px",
              color: "white",
              display: "grid",
              justifyContent: "center",
              height: "32px",
              width: "32px"
            }}>
            <ItemIcon type={item.type} />
          </Box>
        </Box>
        <Box sx={{margin: "15px 0", paddingRight: "10px", wordBreak: "break-word"}}>
          <ItemRelText handleNavigate={() => navigateToPage()} color={'#467993'} nodeId={item.nodeId} pageNumer={item.pageNumber} relText={pdfPage.fulltext} />
        </Box>
      </Box>
    )
  }

  const InteractionItem = ({item}: {item: SearchItemType}) => {
    if(!item.interactionId) return null
    const interaction = pod.getInteraction(item.interactionId)
    if(!interaction) return null

    function navigateToInteraction() {
      if(pod && interaction && item.pageNumber !== null) {
        // inform thread about the scroll
        if(item.type === 'comment' || item.type === 'readingQuestion') {
          uiStore.setScrollToMessage(-1)
        }
        // navigate with url
        const url = `/pod/${pod.podId}/pdf/${item.nodeId}/${interaction.interactionType}/${interaction.interactionId}`
        navigateToUrl(url, item.nodeId)
      }
    }

    const color = interaction.style.color ? interaction.style.color : uiStore.getInteractionColor(item.type as InteractionType)
    return (
      <Box sx={{
        borderBottom: "1px solid #ebebeb",
        display: "grid",
        gridTemplateColumns: "60px auto"
      }}>
        {/* icon */}
        <Box sx={{padding: "5px"}}>
          <Box sx={{
              alignContent: "center",
              borderRadius: "5px",
              backgroundColor: color,
              color: "white",
              display: "grid",
              justifyContent: "center",
              height: "32px",
              width: "32px"
            }}>
            <ItemIcon type={item.type} />
          </Box>
        </Box>
        {/* content */}
        <Box sx={{marginTop: "5px"}}>
          <ItemHeader tModified={interaction.tModified} userName={interaction.userName} />
          <Box sx={{paddingRight: "10px", wordBreak: "break-word"}}>
            {/* interaction rect text */}
            <ItemRelText handleNavigate={() => navigateToInteraction()} color={color} nodeId={item.nodeId} pageNumer={item.pageNumber} relText={interaction.anchor.relText} />
            {/* interaction label */}
            <Box sx={{color: "#313744", fontWeight: 300, whiteSpace: "pre-line"}}>
              {interaction.interactionType === "emotion" ?
                <HighlightedEmotion handleNavigate={() => navigateToInteraction()} emoji={interaction.label} text={interaction.emotionId} toFind={lastSearchTerm} exactSearch={exactSearchFilter} />
                :
                <HighlightedText handleNavigate={() => navigateToInteraction()} text={interaction.label} toFind={lastSearchTerm} exactSearch={exactSearchFilter} />
              }
              {interaction.interactionType === 'tagging' &&
                <TagName handleNavigate={() => navigateToInteraction()} tagId={interaction.tagId} />
              }
              {interaction.interactionType === 'weblink' &&
                <HighlightedText handleNavigate={() => navigateToInteraction()} text={interaction.url} toFind={lastSearchTerm} exactSearch={exactSearchFilter} />
              }
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const TagName= ({handleNavigate, tagId}: {handleNavigate: () => void, tagId: string}) => {
    const tag = podStore.getTagProp(tagId)
    if(!tag) return null
    return (
      <HighlightedText handleNavigate={handleNavigate} text={`#${tag.name}`} toFind={lastSearchTerm} exactSearch={exactSearchFilter} />
    )
  }

  const MessageItem = ({item}: {item: SearchItemType}) => {
    if(!item.messageId) return null
    const message = pod.getMessage(item.messageId, item.threadId)
    if(!message) return null

    function navigateToMessage() {
      if(pod && item.messageIndex !== undefined) {
        // inform thread about the scroll
        uiStore.setScrollToMessage(item.messageIndex)
        // navigate with url
        const url = `/pod/${pod.podId}/pdf/${item.nodeId}/${item.type}/${item.interactionId}`
        navigateToUrl(url, item.nodeId)
      }
    }

    const userInfo = pod.userInfos[message.userId]
    return (
        <Box sx={{
          borderBottom: "1px solid #ebebeb",
          display: "grid",
          gridTemplateColumns: "60px auto"
        }}>
          {/* icon */}
          <Box sx={{padding:'5px'}}>
            <UserInfoAvatar sx={{height:32, width:32}} userId={message.userId} podId={pod.podId} userInfoObject={userInfo} />
          </Box>
          {/* content */}
          <Box>
            {/* message header */}
            <ItemHeader tModified={message.tModified} userName={message.userName} />
            {/* message content */}
            <Box>
              <Box sx={{color: "#313744", fontWeight: 300, paddingRight: "10px", wordBreak: "break-word", whiteSpace: "pre-line"}}>
                <HighlightedText handleNavigate={() => navigateToMessage()} text={message.text} toFind={lastSearchTerm} exactSearch={exactSearchFilter} />
              </Box>
            </Box>
          </Box>
        </Box>
    )
  }

  const ItemIcon = ({type}: {type: InteractionType | "pdf"}) => {
    if(type === "pdf") return <ShortTextIcon />
    return <InteractionIcon interactionType={type} fontSize='small' />
  }

  const ItemHeader = ({tModified, userName}: { tModified: number|null, userName: string|null}) => {
    if(!userName) return null
    return (
      <Box sx={{display: "grid", gridTemplateColumns: "max-content auto", alignItems: "center", marginTop: "5px"}}>
        <Box sx={{fontWeight: "500", color: "#4c4c57", wordBreak: "break-word"}}>
          {userName}
        </Box>
        <Box sx={{marginLeft: "15px", color: "#808080a6", fontSize: "13px"}}>
          <TimeFromNow timestamp={tModified ? tModified*1000 : 0} />
        </Box>
      </Box>
    )
  }

  const ItemRelText = ({color, handleNavigate, nodeId, pageNumer, relText}: {color: string, handleNavigate: () => void, nodeId:string|null, pageNumer: number|null, relText: string}) => {
    const filename = nodeId ? podStore.getFilename(nodeId) : ""
    const pageNumber = pageNumer ? pageNumer : ""
    return(
      <Box sx={{
        backgroundColor: "#f5f5f5",
        display: "grid",
        fontWeight: 350,
        gridTemplateColumns: "min-content auto",
      }}>
        <Box sx={{ backgroundColor: color, marginRight: "15px", width: "5px"}} />
        <Box>
          <Box sx={{
            color: "#467993",
            display: "flex",
            fontSize: "13px",
            justifyContent: "end",
            padding: "5px 10px 0 10px"
          }}>
            {filename}: <span style={{fontWeight: 450, paddingLeft: "3px", wordBreak: "keep-all"}}>{pageNumber}</span>
          </Box>
          {relText.length > 0 ?
            <HighlightedText handleNavigate={handleNavigate} text={relText} toFind={lastSearchTerm} exactSearch={exactSearchFilter} />
            :
            <TextLink handleNavigate={handleNavigate}>
              <p style={{fontStyle: "italic"}}>{t('There is no text available')}</p>
            </TextLink>
          }
        </Box>
      </Box>
    )
  }

  const listOfResults = filteredSearchResult.map((item: SearchItemType, index: number) => {
    // show no more than items in view
    if(index >= maxItemsInView) return null
    // found item is a message
    if(item.type === 'message') return (
      <MessageItem key={item.uid} item={item} />
    )
    // found item is a text page
    if(item.type === 'pdf') return(
      <PageItem key={item.uid} item={item} />
    )
    // found item is an interaction
    return(
      <InteractionItem key={item.uid} item={item} />
    )
  })

  const StatusMessage = ({icon, headerText, text}: {icon: any, headerText: string, text: string}) => (
    <Box sx={{display: "grid", height: "120px", alignContent: "center", justifyContent: "center"}}>
      <Box sx={{display: "grid", gridTemplateColumns: "min-content auto", fontSize: "19px", fontWeight: 450, alignItems: "center", gap: "10px", wordBreak: "break-word"}}>
        {icon}
        {headerText}
      </Box>
      <Box sx={{color: "grey"}}>
        {text}
      </Box>
    </Box>
  )

  const Result = observer(() => {
    // show loading bar if there is no search instance
    if(hasSearchInstance === false) return (
      <LoadingSearch />
    )
    // show message if there are no search results
    if(lastSearchTerm && searchResult.length === 0) return (
      <StatusMessage icon={<SearchIcon />} headerText={t("No results for '{{searchTerm}}'", {searchTerm: lastSearchTerm})} text={t('Check the spelling or try another search.')} />
    )
    // show message if  there are only hidden search results
    if(lastSearchTerm && filteredSearchResult.length === 0) return (
      <StatusMessage icon={<FilterIcon />} headerText={t('Check filter')} text={t('Some search results are currently hidden')} />
    )
    // else: show list of search results
    return (
      <Box sx={{maxWidth: "800px"}}>
        {listOfResults}
        {(filteredSearchResult.length >= maxItemsInView) &&
          <Button sx={{marginTop: "20px"}} fullWidth onClick={showMoreItems}>
            {t('more_results', {count: maxItemsInView-filteredSearchResult.length})}
          </Button>
        }
      </Box>
    )
  })

  return (
    <>
      <Button
        aria-label="open-search"
        onClick={toggleOpenSearch}
        startIcon={<SearchIcon sx={{color: "#263238"}} />}
        sx={{
          backgroundColor: "white",
          borderColor: "white",
          borderRadius: "20px",
          color: "#474747",
          fontSize: "13px",
          fontWeight: 400,
          textTransform: "none",
          ":hover": {
            bgcolor: "whitesmoke",
            outline: "2px solid rgb(25, 118, 210)"
          }
        }}
        variant="outlined"
      >
        {t('Search')}...
      </Button>
      <Dialog
        disableRestoreFocus
        fullScreen={isFullscreenMode ? true : false}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            borderRadius: isFullscreenMode ? "" : "16px",
            height: "100%",
          }
        }}
      >
        <DialogTitle>
          <Box sx={{display: "grid", gridTemplateColumns: "auto max-content", alignItems: "center", padding: "2px 0 8px 8px"}}>
            <Box sx={{fontSize: "19px", fontWeight: "350", padding: "5px 0 0 5px"}}>
              {t('Search the Pod')}
            </Box>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{color: "grey"}} />
            </IconButton>
          </Box>
          <Box sx={{marginRight: "15px"}}>
            {hasSearchInstance &&
              <DebouncedSearchInput inputChange={inputChange} />
            }
          </Box>
          {isFullscreenMode &&
            <Box sx={{display: "grid", justifyContent: "end", margin: "5px 5px -5px 0"}}>
              <SearchFilter isFullscreenMode={isFullscreenMode} mediaSearchFilter={mediaSearchFilter} nodeSearchFilter={nodeSearchFilter} exactSearchFilter={exactSearchFilter} searchResult={searchResult} filteredSearchResult={filteredSearchResult} />
            </Box>
          }
        </DialogTitle>
        <DialogContent style={{overflowY: "scroll"}} >
          {uiStore.searchIsOpen &&
            <Box sx={{display: "grid", gridTemplateColumns: isFullscreenMode ? "auto" : "auto 280px"}}>
              <Result />
              {!isFullscreenMode &&
                <SearchFilter isFullscreenMode={isFullscreenMode} mediaSearchFilter={mediaSearchFilter} nodeSearchFilter={nodeSearchFilter} exactSearchFilter={exactSearchFilter} searchResult={searchResult} filteredSearchResult={filteredSearchResult} />
              }
            </Box>
          }
        </DialogContent>
      </Dialog>
    </>
  )
}

export default observer(Search)

const LoadingSearch = observer(() => {
  const { podStore } = useStore()
  const searchInstanceLoadingProgress = podStore.searchInstanceLoadingProgress
  return (
    <Box sx={{height: "100%", width: "100%", display: "grid", alignContent: "center", padding: "30px"}}>
      <Box sx={{padding: "5px", display: "grid", gridTemplateColumns: "auto max-content", fontSize: "15px"}}>
        {t('Calculate search index...')}
        <Box>
          {searchInstanceLoadingProgress}%
        </Box>
      </Box>
      <LinearProgress variant="determinate" value={searchInstanceLoadingProgress} />
    </Box>
  )
})

const TextLink = ({children, handleNavigate}: {children: React.ReactNode, handleNavigate: () => void}) => (
  <Box onClick={handleNavigate} sx={{
    margin: "8px 32px 16px 0",
    ":hover": {
      cursor: "pointer",
      textDecorationLine: "underline"
    }
  }}>
    {children}
  </Box>
)

const HighlightedEmotion = ({emoji, handleNavigate, text, toFind, exactSearch}:{emoji: string, handleNavigate: () => void, text: string, toFind: string, exactSearch: boolean}) => {
  const searchWords = exactSearch ? [toFind] : toFind.match(tokenizeRegex)
  const indices = searchWords ? getIndices(text, searchWords) : []
  // no search term found in this text, show emoji without highlight
  if(indices.length === 0 || searchWords === null) return (
    <TextLink handleNavigate={handleNavigate}>
      {emoji}
    </TextLink>
  )
  if(indices.length) return (
    <TextLink handleNavigate={handleNavigate}>
      <Box sx={{backgroundColor: "yellow", width: "fit-content"}}>
        {emoji}
      </Box>
    </TextLink>
  )
}


const HighlightedText = ({handleNavigate, text, toFind, exactSearch}:{handleNavigate: () => void, text: string, toFind: string, exactSearch: boolean}) => {
  const windowLength = 150
  const searchWords = exactSearch ? [toFind] : toFind.match(tokenizeRegex)
  const indices = searchWords ? getIndices(text, searchWords) : []
  // no search term found in this text, show text snippet without highlight
  if(indices.length === 0 || searchWords === null) return (
    <TextLink handleNavigate={handleNavigate}>
      {text.slice(0, windowLength)}
    </TextLink>
  )

  // get text snippets where the search term was found
  const listOfSnippets = []
  let windowStart = 0
  let windowEnd = 0
  let lastWindowEnd = 0
  for(const index of indices) {
    if(index >= windowEnd) {
      windowStart = index-windowLength < lastWindowEnd ? lastWindowEnd : index-windowLength
      windowEnd = index + windowLength
      lastWindowEnd = windowEnd
      const textSnippet = text.slice(windowStart, windowEnd)
      // only show textSnippet if all search words are included
      let hasSearchWords = true
      for(const word of searchWords) {
        if(textSnippet.toLowerCase().includes(word.toLowerCase()) === false) hasSearchWords = false
      }
      if(hasSearchWords) listOfSnippets.push(textSnippet)
    }
  }

  if(listOfSnippets.length === 0) return (
    <TextLink handleNavigate={handleNavigate}>
      {text.slice(0, windowLength)}
    </TextLink>
  )

  // all search terms are within the size of the window
  if(listOfSnippets.length === 1) return (
    <TextLink handleNavigate={handleNavigate}>
      <HighlightedSnippet searchWords={searchWords} text={listOfSnippets[0]} />
    </TextLink>
  )

  // offer the possibility to expand further search results
  return (
    <Box sx={{margin: "8px 0 16px 0"}}>
      <TextLink handleNavigate={handleNavigate}>
        <HighlightedSnippet searchWords={searchWords} text={listOfSnippets.shift()} />
      </TextLink>
      <details>
        <summary style={{margin: "5px 0 0 2px", cursor: "pointer", backgroundColor: "white", border: "1px solid #d9d9d9", padding: "5px", borderRadius: "5px", width: "max-content"}}>
          {listOfSnippets.length} {t('more_results', {count: listOfSnippets.length})}
        </summary>
          {listOfSnippets.map((highlight: string, index: number) => (
            <Box key={index} sx={{margin: "15px 0"}}>
              <TextLink handleNavigate={handleNavigate}>
                <HighlightedSnippet searchWords={searchWords} text={highlight} />
              </TextLink>
            </Box>
          ))}
      </details>
    </Box>
  )
}

const HighlightedSnippet = ({searchWords, text}: {searchWords: string[], text: string|undefined}) => {
  if(!text) return null
  return (
    <Highlighter
      highlightClassName="highlight"
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={text}
    />
  )
}

function getIndices(text: string, searchWords: string[]) {
  const indices = []
  const textLowerCase = text.toLowerCase()
  // find indices for each search word
  for(const word of searchWords) {
    const toFindLowerCase = word.toLocaleLowerCase()
    let i = -1
    while ((i = textLowerCase.indexOf(toFindLowerCase, i+1)) != -1){
      indices.push(i)
    }
  }
  // sort results to determine the text snippets
  if(searchWords.length > 1) {
    indices.sort(function(a, b) {
      return a - b
    })
  }
  return indices
}


function hasValidWordWindow(text: string, toFind: string) {
  const windowLength = 140
  const searchWords = toFind.match(tokenizeRegex)
  const indices = searchWords ? getIndices(text, searchWords) : []

  if(indices.length === 0 || searchWords === null) return false

  let windowStart = 0
  let windowEnd = 0
  let lastWindowEnd = 0
  for(const index of indices) {
    if(index >= windowEnd) {
      windowStart = index-windowLength < lastWindowEnd ? lastWindowEnd : index-windowLength
      windowEnd = index + windowLength
      lastWindowEnd = windowEnd
      const textSnippet = text.slice(windowStart, windowEnd)
      // test if all search words are contained in the text window
      let isValid = true
      for(const word of searchWords) {
        if(textSnippet.toLowerCase().includes(word.toLowerCase()) === false) isValid = false
      }
      if(isValid) return true
    }
  }
  return false
}

// https://www.developerway.com/posts/debouncing-in-react
const DebouncedSearchInput = ({inputChange}: {inputChange: () => void}) => {
  const { podStore, uiStore } = useStore()
  const [toFind, setToFind] = useState("")
  const ref = useRef<any>()

  useEffect(() => {
    // restore last search and select text from search input
    const lastSearchTerm = uiStore.lastSearchTerm
    const searchResult = uiStore.searchResult
    if(searchResult.length && lastSearchTerm.length && toFind !== lastSearchTerm) {
      setToFind(lastSearchTerm)
      setTimeout(()=> {
        const inputElement = document.getElementById('search-pod-input') as HTMLInputElement
        if(inputElement) {
          inputElement.focus()
          inputElement.select()
        }
      })
    }
  }, [])

  const onChange = () => {
    // get search results and set it for view
    const searchResult = podStore.searchPod(toFind)
    uiStore.setSearchResult(searchResult)
    // save search term to set it when reopening the search
    uiStore.setLastSearchTerm(toFind)
  }

  useEffect(() => {
    ref.current = onChange
  }, [onChange])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.()
    }
    return debounce(func, 500)
  }, [])

  return (
    <OutlinedInput
      autoComplete="off"
      autoFocus
      fullWidth
      id="search-pod-input"
      onChange={(e) => {
        debouncedCallback()
        setToFind(e.target.value)
        inputChange()
      }}
      startAdornment={<SearchIcon sx={{color: "#263238", marginRight: "10px"}} />}
      value={toFind}
    />
  )
}
