import { t } from "i18next"
import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import { useStore } from "../../contexts/store"
import { MediaSearchFilterItemType, MediaSearchFilterType } from "../../stores/uiStore"
import { SearchListType } from "../../stores/podStore"

import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import {  Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, IconButton, Switch, Typography } from "@mui/material"
import FilterIcon from '@mui/icons-material/FilterAltOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowForwardIos'


// define transition for filter dialog on small screens
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />
})


const SearchFilter = ({isFullscreenMode, mediaSearchFilter, nodeSearchFilter, exactSearchFilter, searchResult, filteredSearchResult}: {isFullscreenMode: boolean, mediaSearchFilter: MediaSearchFilterType, nodeSearchFilter: string[], exactSearchFilter: boolean, searchResult: SearchListType, filteredSearchResult: SearchListType}) => {
  const [openFilter, setOpenFilter] = useState(false)
  const { podStore, uiStore } = useStore()
  if(!podStore.pod) return null
  // do not show filter elements if there is no search result
  if(searchResult.length === 0) return null

  // items that can be filtered
  const pdfFiles = podStore.pod.getPdfFiles()
  const mediaList: MediaSearchFilterType = [
    "pdf",
    "message",
    "annotation",
    "comment",
    "link",
    "tagging",
    "weblink",
    "emotion",
    "readingQuestion"
  ]

  // used only on small screen
  const handleOpenFilter = () => {
    setOpenFilter(true)
  }
  const handleCloseFilter = () => {
    setOpenFilter(false)
  }

  function getNumberOfItems(itemId: string, checked: boolean) {
    // for unselected filter elements show number of available items
    // for selected filter elements show the number of current shown items
    if(checked) {
      const items = filteredSearchResult.filter(item => item.type === itemId || (item.nodeId === itemId && item.type !== 'message'))
      return items.length
    } else {
      const items = searchResult.filter(item => {
        if(itemId === 'message' && item.type === 'message') return true
        if(item.type === itemId && item.nodeId && !nodeSearchFilter.includes(item.nodeId)) return true
        if(item.nodeId === itemId && item.type !== 'message' && !mediaSearchFilter.includes(item.type)) return true
        return false
      })
      return items.length
    }
  }

  const FilterElements = observer(() => (
    <Box sx={{margin: "0 10px"}}>
      {/* exact search switch */}
      <ExactSearchSwitch />
      {/* filter for media type */}
      <Box sx={{marginTop: "20px"}}>
        <FilterListHeader title={t("Type")} />
        <FormGroup>
          {mediaList.map(media => {
            return (
              <FilterListItem
                checked={!mediaSearchFilter.includes(media)}
                item={(media === 'message') ? t('Conversations') : t(media)}
                itemId={media}
                key={media}
                onChange={(media) => uiStore.toggleMediaSearchFilter(media as MediaSearchFilterItemType)}
              />
            )
          }
          )}
        </FormGroup>
      </Box>
      {/* filter for nodes */}
      <Box sx={{marginTop: "20px"}}>
        <FilterListHeader title={t("Material")} />
        <FormGroup>
          {pdfFiles.map(file => {
            const nodeId = file.nodeId
            const filename = podStore.getFilename(nodeId)
            if(!filename) return null
            return (
              <FilterListItem
                checked={!nodeSearchFilter.includes(nodeId)}
                item={filename}
                itemId={nodeId}
                key={nodeId}
                onChange={(nodeId) =>uiStore.toggleNodeSearchFilter(nodeId)}
              />
            )
          }
          )}
        </FormGroup>
      </Box>
      {/* discard filter button */}
      {(mediaSearchFilter.length > 0 || nodeSearchFilter.length > 0 || exactSearchFilter) &&
        <Box sx={{margin: "20px 5px 10px 5px"}}>
          <Button onClick={(() => uiStore.clearSearchFilter())} size="small" fullWidth variant="outlined" sx={{
            borderColor: "rgb(0, 175, 200)",
            borderBottom: "2px solid rgb(0, 175, 200)",
            color: "rgb(0, 175, 200)",
            ":hover": {
              borderColor: "rgb(0, 175, 200)",
              borderBottom: "2px solid rgb(0, 175, 200)"
            }
          }}>
            {t('Discard filter')}
          </Button>
        </Box>
      }
    </Box>
  ))

  const FilterListHeader = ({title}: {title: string}) => (
    <Box sx={{display: "grid", gridTemplateColumns: "max-content auto", alignItems: "center", gap: "5px", backgroundColor: "#f2fafa", color: "rgb(0, 175, 200)", fontSize: "16px", fontWeight: 450, margin: "5px 0", padding: "5px"}}>
      <FilterIcon fontSize="small" sx={{color: "rgb(0, 175, 200)"}} /> {title}
    </Box>
  )

  const FilterListItem = observer(({checked, item, itemId, onChange}: {checked: boolean, item: string, itemId: string, onChange: (item: string) => void}) => (
    <FormControlLabel
      sx={{backgroundColor: "#f2fafa", margin: 0, padding: "0 5px"}}
      label={
      <Typography sx={{ color: "#313744", fontWeight: 300, fontSize: "16px", padding: "2px 15px 2px 8px", wordBreak: "break-word" }}>
        {item} <span style={{color: "#6b6b6b"}}>({getNumberOfItems(itemId, checked)})</span>
      </Typography>
    } control={
      <Checkbox
        checked={checked}
        onChange={() => onChange(itemId)}
        sx={{
          alignSelf: "start",
          padding: "5px 0",
          color: "rgb(0, 175, 200)",
          '&.Mui-checked': {
            color: "rgb(0, 175, 200)",
          },
          '& .MuiSvgIcon-root': { fontSize: "18px" }
        }}
      />
    }
    />
  ))

  const ExactSearchSwitch = observer(() => (
    <FormControlLabel
      sx={{background: "#f2fafa", margin: 0, padding: "0 5px", width: "100%"}}
      label={
        <Typography sx={{ color: "#313744", fontWeight: 300, padding: "2px 15px 2px 8px", wordBreak: "break-word" }}>
          {t('Exact Search')}
        </Typography>
      } control={
        <Switch
          checked={exactSearchFilter}
          onChange={() => uiStore.toggleExactSearchFilter()}
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: "rgb(86 196 211)",
              '&:hover': {
                backgroundColor: "rgb(86 196 211 / 20%)",
              },
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: "rgb(86 196 211 / 80%)",
            },
          }}
        />
      }
    />
  ))

  // show search filter as icon which opens a sidebar
  if(isFullscreenMode) {
    return (
      <>
      <Button
        aria-label="open-filter"
        onClick={handleOpenFilter}
        startIcon={<FilterIcon sx={{color: "#263238"}} />}
        sx={{
          color: "#474747",
          fontSize: "13px",
          fontWeight: 400,
        }}
        variant="text"
      >
        {t('Filter')}
      </Button>
      <Dialog
        disableRestoreFocus
        fullScreen={true}
        fullWidth
        onClose={handleCloseFilter}
        open={openFilter}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Box sx={{
            alignItems: "center",
            color: "#474747",
            display: "grid",
            fontSize: "19px",
            fontWeight: 350,
            gap: "10px",
            gridTemplateColumns: "max-content auto",
            paddingTop: "10px"
          }}>
            <IconButton size="small" aria-label="close-filter" onClick={handleCloseFilter}>
              <ArrowBackIcon fontSize="small" sx={{color: "#263238", fontSize: "14px"}} />
            </IconButton>
            {t('Filter')}
          </Box>
        </DialogTitle>
        <DialogContent>
          <FilterElements />
        </DialogContent>
      </Dialog>
    </>
    )
  }

  // show search filter as sidebar element
  return (
    <FilterElements />
  )
}

export default observer(SearchFilter)