import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'wouter'
import { useStore } from '../../contexts/store'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'wouter'
import { Grid, Box, Button, Card, CardContent, Divider, ListItemIcon, ListItemText, Paper, Stack, Typography } from '@mui/material'
import { IconButton } from '@mui/material'
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReplayIcon from '@mui/icons-material/Replay';
import { env } from '../../api/api'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import dayjs from 'dayjs'

import { Pod, Usergroup } from '../../shared/src/types/Pod'
import PodImpro from '../Dev/PodImpro'              // @hagen: bitte bis auf weiteres drin lassen. I know it's ugly, aber solange ich Deinen branch immer wieder bei mir reinmerge, ist das doof sonst :)
import { Op_addUserToPod, Op_deletePod, Op_removeUserFromPod } from '../../shared/src/types/Ops'
import { PodClass } from '../../classes/Pod'
import ConfirmDialog from '../Elements/ConfirmDialog'
import { DeleteForever, MoreVert } from '@mui/icons-material'
import EditUserInfo from '../Pod/EditUserInfo'
import { UserInfo } from '../../shared/src/types/User'
import AddPod from '../Pod/AddPod'
import UserInfoAvatar from '../Elements/UserInfoAvatar'
import { shortDate } from '../../helper/Helper'


const Home: React.FC = () => {
  const { t } = useTranslation()
  const { sessionStore, podStore, opStore, uiStore } = useStore()
  const { session } = sessionStore
  const pods = session.pods
  const [ anchorEl, setAnchorEl ] = React.useState<null | Element>(null)
  const [ activePod, setActivePod ] = React.useState<null|Pod>(null)
  const [ confirmPodReset, setConfirmPodReset ] = React.useState<null|string>(null)
  const [ showDeleteDialog, setShowDeleteDialog ] = React.useState<Pod|null>(null)
  const [ createNewPod, setCreateNewPod ] = React.useState<boolean>(false)
  const [ newPodName, setNewPodName ] = React.useState<string>('')
  const [ userInfo, setUserInfo ] = React.useState<UserInfo|null>(null)
  const navigate = useLocation()[1]
  const ownPods = pods.filter(p => p.creator?.userId === session.user.userId).length

  const deviceWidth = uiStore.deviceWidth
  const containerPadding = (deviceWidth === "tiny" || deviceWidth === 'smaller') ? 16 : 40

  useEffect(() => {
    podStore.unsetPod()
  })

  const handleClick = (event: React.MouseEvent<Element>, pod:Pod) => {
    setAnchorEl(event.currentTarget)
    setActivePod(pod)
    event.stopPropagation()
  }

  const handleClose = () => {
    setAnchorEl(null)
    setActivePod(null)
  }

  const leavePod = (userId:number, podId:string) => {
    const p = sessionStore.session.pods.find(p => p.podId === podId)
    if (p) {
      const pod = new PodClass(p)
      const op:Op_removeUserFromPod = {
        op: 'removeUserFromPod',
        podId: podId,
        data: {
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          userId,
          userName: pod.userInfos[userId].userName || '',
        }
      }
      opStore.doOp(op)
    }
  }

  const deletePod = (podId:string) => {
    if (!podId) return
    const pod = session.pods.find(p => p.podId === podId)
    if (!pod) return
    const podUsergroupId = Object.keys(pod.usergroups).find((id) => pod.usergroups[id].role === 'Pod')
    if (!podUsergroupId) return

    const op:Op_deletePod = {
      op: 'deletePod',
      podId: podId,
      data: {
        userId: sessionStore.session.user.userId,
        userName: pod.userInfos[sessionStore.session.user.userId].userName || '',
        usergroupId: podUsergroupId,
      }
    }
    opStore.doOp(op)
  }

  const doCreatePod = (info:UserInfo) => {
    if ((newPodName) && (info) && (info.userName)) {
      const op:Op_addUserToPod = {
        op: 'addUserToPod',
        podId: sessionStore.createUuid(),
        data: {
          podName: newPodName,
          createPod:true,
          usergroupId: '',
          userId: sessionStore.session.user.userId,
          login: sessionStore.session.user.login,
          userName: info.userName,
          color:info.color,
          type: 'request',
          keyphrase: 'CREATE'
        }
      }
      opStore.doOp(op)
    }

    setCreateNewPod(false)
    setUserInfo(null)
  }

  const suggestReset = (e:React.MouseEvent, podId:string) => {
    const pod = sessionStore.session.pods.find((pod:Pod) => pod.podId === podId)
    if (pod && pod.outOfSync) {
      setConfirmPodReset(podId)
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const clearSearch = () => {
    // clear search from last pod
    podStore.clearSearch()
  }

  // TODO: create message if there are no pods available
  if(pods === null) return <div>no pods available</div>

  const sx = {
    height: "50px",
    mt: 1,
    mb: 1,
    "&:hover": {
      backgroundColor: "whitesmoke"
    }
  }

  const podsList = pods.slice().sort((p1:Pod, p2: Pod) => { return ('' + p1.name).localeCompare(p2.name); }).map((pod: Pod) => {
    let status = null

    const headerStyles:React.CSSProperties = {
      height:80,
      backgroundColor: pod.podImageFileId ? pod.podImageFileId : '#eee',
      paddingLeft:'10px',
      display:'flex',
      alignItems: 'center',
      overflow: 'hidden',
      width: '100%',
    }

    if (pod.podImageFileId) {
      headerStyles.backgroundImage = `url(${env.backendBaseUrl}/file/${pod.podImageFileId})`
      headerStyles.backgroundSize = 'cover'
      headerStyles.backgroundPositionY = 'center'
    }

    if (pod.podColor) {
      headerStyles.backgroundColor = pod.podColor
    }

    const headerHeadingStyle:React.CSSProperties = {
      fontWeight: 700,
      marginRight: '40px',
      marginBottom: '10px',
      fontSize:'1.1rem',
      maxWidth: '100%',
      overflow: 'hidden',
      lineHeight:'1.4rem',
      height:'56px',
      /*whiteSpace: 'nowrap', //*/
    }


    if (pod.outOfSync) status = <Box sx={{position:'absolute', top:2, right:2}} title={t('This pod seems to be out of sync. It is recommended that you reset it. Click for more.') as string}><SyncProblemIcon sx={{backgroundColor:'white', borderRadius:10}} color='warning' /></Box>
    return  <Grid item xs={12} sm={6} md={6} lg={4} key={pod.podId} sx={{}}>
      <Link to={`pod/${pod.podId}/contents`} onClick={(e) => {suggestReset(e, pod.podId); clearSearch()}} style={{textDecoration: "none"}}>
        <Card elevation={2} sx={{...sx, height:200, position:'relative', cursor:'pointer'}}>
          <div style={{ height:'100%', display:'grid', gridTemplateRows: 'min-content auto min-content'}}>
            <div style={headerStyles}>
            </div>
            <CardContent sx={{position:'relative'}}>
              <Typography variant="body1" component="h2" sx={headerHeadingStyle}>
                {pod.name}
              </Typography>
              <IconButton sx={{position:'absolute', right:'2px', top:'2px', backgroundColor:'#fff8'}} size='small' onClick={(e:React.MouseEvent) => handleClick(e, pod) }>
                <MoreVert fontSize='small' />
              </IconButton>
              <div style={{overflow:'hidden', marginRight:10, maxHeight:'48px'}}>
                {pod.status === 'requested' ? <span>&nbsp;(access requested)</span> : null}
              </div>
              <Stack direction="row" spacing={2} sx={{mb: 2, justifyContent: "space-between", }}>
                <UserInfoAvatar userId={pod.creator?.userId || 0} podId={pod.podId} sx={{height:24, width:24, fontSize:'80%'}} />
                <Box style={{flexGrow:2}}>{pod.creator?.userName}</Box>
              </Stack>
              {status}
            </CardContent>
          </div>
        </Card>
      </Link>
    </Grid>
  })

  return (
    <Box className="overflowWrapper" id="pod-container" sx={{
      display: "grid",
      gridTemplateRows: "max-content auto",
    }}>
      <Box sx={{backgroundColor: "white"}}>
        <Box sx={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: `${1280 + (2*containerPadding)}px`,
          paddingTop: `${(deviceWidth === 'medium' || deviceWidth === 'large') ? 40 : 0}px`,
          paddingLeft: `${containerPadding}px`,
          paddingRight: `${containerPadding}px`,
          marginBottom: "5px"
        }}>
        <Box sx={{ alignContent: "start", p: 5 }}>
          <h2>{t('Welcome to SHRIMP!')} 👋</h2>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>{podsList}</Grid>
        </Box>
        <Box sx={{ p: 5 }} style={{textAlign:'right'}} title={t('You can create {{nof}} more new pods', {nof: (session.user.podLimit - ownPods)}) as string}>
          <Button variant="outlined" onClick={() => { setCreateNewPod(true) }} disabled={session.user.podLimit <= ownPods}>{t('Create New Pod')}</Button>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={(e) => { if (activePod?.podId) podStore.resetPod(activePod?.podId); handleClose(); e.stopPropagation() }}>
            <ListItemIcon>
              <ReplayIcon />
            </ListItemIcon>
            <ListItemText>{t('Reload this pod')}</ListItemText>
          </MenuItem>

          <MenuItem onClick={(e) => { if (activePod?.podId) leavePod(sessionStore.session.user.userId, activePod?.podId); handleClose(); e.stopPropagation() }}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>{t('Leave this pod')}</ListItemText>
          </MenuItem>

          { (activePod?.creator?.userId === sessionStore.session.user.userId) ? <nav key="deldiv" aria-label="dangerous delete option">
            <Divider style={{margin:'8px 0'}} />
            <MenuItem key='delete' onClick={(e:React.MouseEvent) => { setShowDeleteDialog(activePod); handleClose(); e.stopPropagation() }}>
              <ListItemIcon>
                <DeleteForever sx={{color:"darkred"}} />
              </ListItemIcon>
              <ListItemText sx={{color:"darkred"}}>{t('Delete this pod')}</ListItemText>
            </MenuItem>
          </nav> : null }

        </Menu>
        <ConfirmDialog
          title={t('Pod Out of Sync')}
          info={t('The Pod seems to be out of sync. To make sure') as string}
          open={Boolean(confirmPodReset)}
          handleClose={() => { setConfirmPodReset(null); navigate(`pod/${confirmPodReset}/contents`) }}
          onOk={() => { if (confirmPodReset) podStore.resetPod(confirmPodReset) }}
        />
        <ConfirmDialog title={t('Permanently Delete this Pod?')}
          info={t('Deleting this pod \"{{podname}}\" will also delete all files, interactions, and conversations it contains. All contents of the pod will be lost. This cannot be undone. Are you sure?', {podname: showDeleteDialog?.name}) ||''}
          open={Boolean(showDeleteDialog)}
          handleClose={() => { setShowDeleteDialog(null)} }
          onOk={() => deletePod(showDeleteDialog?.podId || '')} />
        <AddPod open={createNewPod === true} handleClose={() => setCreateNewPod(false)} />
        <EditUserInfo userInfo={userInfo} handleClose={doCreatePod} />
        {env.dev ? <PodImpro /> : null}
      </Box>
    </Box>
  </Box>
  )

}

export default observer(Home)