import i18next from 'i18next'
import { makeObservable, observable, action } from 'mobx'
import sessionStore from './sessionStore'
import alertStore, {alert} from "../stores/alertStore"
import exportOpStore from './opStore'
import exportUiStore from './uiStore'
import PodStore from './podStore'
import { ServiceWorkerStatus } from '../shared/src/types/Sync'
import { Pod, PodInfo } from '../shared/src/types/Pod'

export interface broadcastStoreModel {
    serviceWorkerStatus:ServiceWorkerStatus
    createBroadcastChannel: () => void
    removeBroadcastChannel: () => void
    sendMessage: (msg: any) => void
  }


class broadcastStore {
    broadcastChannel: BroadcastChannel | null = null

    serviceWorkerStatus:ServiceWorkerStatus = {
        syncQueueLength: 0,
        lastSyncTimestamp: 0,
        lastSyncStatus: 200,
    }

    podInfo: {[podId:string]:PodInfo} = {}

    constructor() {
        makeObservable(this, {
            broadcastChannel: observable,
            serviceWorkerStatus: observable,
            podInfo: observable,
            gotMessage: action,
            createBroadcastChannel: action,
            removeBroadcastChannel: action,
            sendMessage: action,
        })
    }

    createBroadcastChannel() {
        if(this.broadcastChannel === null) {
            // TODO: use sessionId or some uuid as name to provide security
            const channel: BroadcastChannel = new BroadcastChannel('03dbc9e7-db11-4a22-b4fe-938b73d68653')
            this.broadcastChannel = channel
            // listen to messages from other tabs with the shrimp application
            channel.addEventListener('message', this.gotMessage.bind(this))
        }
    }

    removeBroadcastChannel() {
        this.broadcastChannel?.removeEventListener('message', this.gotMessage)
        this.broadcastChannel?.close()
        this.broadcastChannel = null
    }

    // TODO: user typescript object
    sendMessage(msg: any) {
        // TODO: open new broadcast channel if no one exists
        if (!this.broadcastChannel) {
            this.createBroadcastChannel()
        }
        if (this.broadcastChannel) {
            this.broadcastChannel.postMessage(msg)
        }
        else {
            console.error("Failed to send message. There is no broadcast channel.")
        }
    }

    gotMessage(ev: MessageEvent) {
        if(ev && ev.data && ev.data.op) {
            let msg = ev.data
            switch (msg.op) {
                case "syncMsg":
                    const clientsPendingOps = exportOpStore.static.queue
                    if (exportUiStore.showVerboseLogging.sync && clientsPendingOps.length) console.log(`Include ${clientsPendingOps.length} unsynched Ops still in the Client in the updates: [${clientsPendingOps.map(op => op.op).join(',')}]`)
                    const importables = []
                    if (msg.clientUpdates) importables.push(...msg.clientUpdates)
                    if (clientsPendingOps) importables.push(...clientsPendingOps)
                    exportOpStore.importOps(msg.ops, importables)
                    break;

                case "setServiceWorkerStatus":
                    this.serviceWorkerStatus = msg.serviceWorkerStatus
                    break

                case "setIsOffline":
                    exportUiStore.setIsOffline(msg.isOffline)
                    break

                case "syncError":
                    alertStore.push(alert(i18next.t('Syncing your changes failed') + `: "${msg.message}"\n→ ` + i18next.t('Please click for more'), 'error', i18next.t('Sync Error') + ` ${msg.status}`, undefined, '/syncing'))
                    break;

                case "editPodInfo":

                    if (typeof this.podInfo[msg.podId] === 'undefined') this.podInfo[msg.podId] = { status: 'unknown', serviceWorkerFingerprint:'', backendFingerprint:'', outOfSync:false }

                    if (PodStore.pod && (PodStore.pod.podId === msg.podId)) {
                        if (typeof msg.mods.status                   !== 'undefined') PodStore.pod.status                   = msg.mods.status
                        if (typeof msg.mods.outOfSync                !== 'undefined') PodStore.pod.outOfSync                = msg.mods.outOfSync
                        if (typeof msg.mods.serviceWorkerFingerprint !== 'undefined') PodStore.pod.serviceWorkerFingerprint = msg.mods.serviceWorkerFingerprint
                        if (typeof msg.mods.backendFingerprint       !== 'undefined') PodStore.pod.backendFingerprint       = msg.mods.backendFingerprint
                    }

                    if (sessionStore.session.pods.findIndex((p:Pod) => p.podId === msg.podId)>-1) {
                        const i = sessionStore.session.pods.findIndex((p:Pod) => p.podId === msg.podId)
                        if (typeof msg.mods.status                   !== 'undefined') sessionStore.session.pods[i].status                   = msg.mods.status
                        if (typeof msg.mods.outOfSync                !== 'undefined') sessionStore.session.pods[i].outOfSync                = msg.mods.outOfSync
                        if (typeof msg.mods.serviceWorkerFingerprint !== 'undefined') sessionStore.session.pods[i].serviceWorkerFingerprint = msg.mods.serviceWorkerFingerprint
                        if (typeof msg.mods.backendFingerprint       !== 'undefined') sessionStore.session.pods[i].backendFingerprint       = msg.mods.backendFingerprint
                    }

                    break;

                case "logout":
                    if(sessionStore.session) {
                        sessionStore.clearSession()
                        alertStore.push(alert(i18next.t('You have been successfully logged out from another window'), 'success'))
                    }
                    break

                default:
                    console.log(`BroadcastStore: received unknown broadcast message ${msg.op}`)
                    break;
            }
        } else {
            console.error("Incoming message has no data or op property", ev)
        }
    }

}

const exportBroadcastStore = new broadcastStore()
export default exportBroadcastStore
