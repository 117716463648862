import { t } from 'i18next'
import React, { useState, memo, CSSProperties, useEffect, useMemo, useRef } from 'react'
import { useParams } from '../../helper/Helper'
// components
import Error from '../LoadError'
// pdf
import { Document, Page, pdfjs } from 'react-pdf'
import { PDFDocumentProxy } from 'pdfjs-dist'
import 'react-pdf/dist/esm/Page/TextLayer.css'
// virtualization
import { VariableSizeList, FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
// MUI
import { Box, Button } from '@mui/material'

// State Management
import { useStore } from '../../contexts/store'
import { observer } from 'mobx-react-lite'

// Components
import Overlay from './Overlay'
import MarginaliaOverlay from './MarginaliaOverlay'
import LoadingPdf from './LoadingPdf'
import PageClickEvents from './PageClickEvents'
import PageMargin from './PageMargin'
import Infobox from '../Infobox'
import { navigate } from 'wouter/use-location'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'


const Pdf = ({
    pageListRef,
    pdfId,
    pdfUrl,
    podId,
    interactionEditId,
    storeId,
    thumbListRef,
    thumbnailUrl
  }: {
    pageListRef: any,
    pdfId: string,
    pdfUrl: string,
    podId: string,
    interactionEditId?: string,
    storeId: string,
    thumbListRef?: any,
    thumbnailUrl: string
  }) => {
  const params = useParams()
  const { uiStore, pdfMetaStore, podStore } = useStore()
  const [loadingError, setLoadingError] = useState('')
  const hasVariableSize = pdfMetaStore.getHasVariableSize(storeId)
  const numPages = pdfMetaStore.getNumPage(storeId)
  const pageHeights = pdfMetaStore.getPageHeights(storeId)
  const pageWidths = pdfMetaStore.getPageWidths(storeId)
  const scale = pdfMetaStore.getScale(storeId)
  const pod = podStore.pod
  // in comparison to pageListRef independent of react-window and its functions
  const outerPageListRef = useRef<HTMLInputElement>(null)

  // counter that ensures that a rerender can be triggered outside the component
  // eslint-disable-next-line
  const _forceUpdate = uiStore.forceViewerUpdateCounter

  useEffect(() => {
    // on mount: initialize pdfMeta in store
    if(pdfMetaStore.isInitialized(storeId) === false) {
      pdfMetaStore.initPdfMeta(storeId, pdfUrl, thumbnailUrl)
    }

    // on unmount: reset loading status and reset edited message
    return () => {
      pdfMetaStore.setLoaded(storeId, false)
      pdfMetaStore.setHasVariableSize(storeId, null)
      pdfMetaStore.setScaleAnchor(storeId, null)
      uiStore.setEditedMessageId(null)
      pdfMetaStore.setPinchStartDistance(storeId, null)
      // stop view tracker on unmount
      const activePage = pdfMetaStore.getPageInView(storeId)
      podStore.setView(false, 'pdfPage', pdfId, activePage)
    }
  }, [pdfMetaStore, uiStore, storeId, pdfUrl, thumbnailUrl, pdfId, podStore])

  if (!pod) return null

  // set the parameters of the pdf after loading and calculate viewport
  const onDocumentLoadSuccess = (pdf: any) => {
    if(pdf && pdf.numPages) {
      calculateViewport(pdf)
    }
  }

  // save the width and height of each pdf page and test if all pages are the same size
  const calculateViewport = async (pdf: PDFDocumentProxy) => {
    if(pdf && scale) {
      let heights: number[] = []
      let widths: number[] = []
      // get height and width from each page
      for(let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i)
        let viewport = page.getViewport({scale: scale})
        // compare each page with the first to see if all pages are the same size
        if(Array.isArray(heights) && heights.length && heights[0] !== viewport.height) {
          pdfMetaStore.setHasVariableSize(storeId, true)
        }
        else {
          pdfMetaStore.setHasVariableSize(storeId, false)
        }
        heights.push(viewport.height)
        widths.push(viewport.width)
      }
      // update store
      pdfMetaStore.setNumPages(storeId, pdf.numPages)
      pdfMetaStore.setPageHeights(storeId, heights)
      pdfMetaStore.setPageWidths(storeId, widths)
      // save inital page widths and heights for thumbnails
      pdfMetaStore.setStaticPageHeights(storeId, heights)
      pdfMetaStore.setStaticPageWidths(storeId, widths)
      pdfMetaStore.setLoaded(storeId, true)
    }
  }

  const getPageHeight = (pageIndex: number) => {
    if(Array.isArray(pageHeights) && pageHeights.length && pageHeights[pageIndex] !== undefined) return pageHeights[pageIndex]
    else {
      console.error("error getPageHeight on index", pageIndex, pageHeights)
      return 0
    }
  }

  const getPageWidth = (pageIndex: number) => {
    if(Array.isArray(pageWidths) && pageWidths.length && pageWidths[pageIndex] !== undefined) return pageWidths[pageIndex]
    else {
      console.error("error getPageWidth on index", pageIndex, pageWidths)
      return 0
    }
  }

  /** loads the pdf page selected by react-window:
   * when scrolling or loading, an image of the page is displayed to increase performance
   * if the page has not been loaded yet, show only one image without loading other pages in the background,
   * so that not too many elements have to be loaded at once when scrolling fast.
   * memo: prevent unnecessary re-rendering of pages when properties of the parent component are changed
   */
  const Segment = memo( ({ index, isScrolling, style }: {index: number, isScrolling: boolean, style: CSSProperties}) => {
    // prevent elements from loading before the page count of the pdf has been loaded
    if(pdfMetaStore.getLoaded(storeId) === false) return null
    // reference to pdf page, if page has not been created yet it is null
    const page = document.getElementById(`${storeId}-page-${index}`)
    const pageHeight = getPageHeight(index)
    const pageWidth = getPageWidth(index)
    const pageMargin = uiStore.pageMargin ? uiStore.pageMargin*scale: 0

    const pageStyle = {
      backgroundColor: "whitesmoke",
      display: "grid",
      gridTemplateColumns: "max-content max-content max-content",
      height: pageHeight+30,
      justifyContent: "center",
      padding: "15px 0",
      // prevent the page margins from being cut off when zooming in
      minWidth: pageWidth + 2*pageMargin
    }

    var marginLeft  = (pod.content.pdfFiles[pdfId].pages[index+1].mLeft - 50) * scale
    var marginRight = (pod.content.pdfFiles[pdfId].pages[index+1].mRight) * scale

    if(isScrolling && page === null) {
      return (
        <div style={{...style, ...pageStyle}}>
            <PageMargin
              height={pageHeight}
              interactionEditId={interactionEditId}
              scale={scale}
              transformOrigin='top right'
              position='left'
              margin={null}
            />
            <img
              alt={`pdf page ${index+1}`}
              height={pageHeight}
              src={thumbnailUrl+"/"+(index+1)}
              width={pageWidths?.length ? getPageWidth(index) : "100%"}
            />
            <PageMargin
              height={pageHeight}
              interactionEditId={interactionEditId}
              scale={scale}
              transformOrigin='top left'
              position='right'
              margin={null}
            />
        </div>
      )
    }
    else return (
      <>
        <div id={`${storeId}-preview-image-${index}`} style={{...style, ...pageStyle}}>
            <PageMargin
              height={pageHeight}
              interactionEditId={interactionEditId}
              scale={scale}
              transformOrigin='top right'
              position='left'
              margin={marginLeft}
            />
            <img
              alt={`pdf page ${index+1}`}
              height={pageHeight}
              src={thumbnailUrl+"/"+(index+1)}
              width={pageWidths?.length ? getPageWidth(index) : "100%"}
            />
            <PageMargin
              height={pageHeight}
              interactionEditId={interactionEditId}
              scale={scale}
              transformOrigin='top left'
              position='right'
              margin={marginRight}
            />
        </div>
        <div id={`${storeId}-page-${index}`} className='hidden' style={{...style, ...pageStyle}}>
            <PageMargin
              height={pageHeight}
              interactionEditId={interactionEditId}
              scale={scale}
              transformOrigin='top right'
              position='left'
              margin={marginLeft}
            />
            <Page
              onRenderSuccess={() => pageRendered(index)}
              pageNumber={index+1}
              scale={scale}
              renderAnnotationLayer={false}
            />
            <PageMargin
              height={pageHeight}
              interactionEditId={interactionEditId}
              scale={scale}
              transformOrigin='top left'
              position='right'
              margin={marginRight}
            />
            <MarginaliaOverlay
              childrenDepth={0}
              interactionEditId={interactionEditId}
              interactionsShown={["annotation"]}
              pageId={`${storeId}-page-${index}`}
              podId={podId}
              nodeId={pdfId}
              pageNumber={index+1}
            />
            <Overlay
              childrenDepth={1}
              interactionEditId={interactionEditId}
              pageId={`${storeId}-page-${index}`}
              pdfId={pdfId}
              pageNumber={index+1}
              scale={scale}
            />
            <MarginaliaOverlay
              childrenDepth={2}
              interactionEditId={interactionEditId}
              interactionsShown={["comment", "emotion", "link", "tagging", "weblink", "readingQuestion"]}
              pageId={`${storeId}-page-${index}`}
              podId={podId}
              nodeId={pdfId}
              pageNumber={index+1}
            />
            <PageClickEvents
              currentScale={scale}
              interactionEditId={interactionEditId}
              pageNumber={index}
              pdfId={pdfId}
              storeId={storeId}
            />
        </div>
      </>
    )
  })

  const pageRendered = (index: number) => {
    const page = document.getElementById(`${storeId}-page-${index}`)
    const previewImage = document.getElementById(`${storeId}-preview-image-${index}`)
    if(page && previewImage) {
      page.classList.remove("hidden")
      previewImage.classList.add("hidden")
    }
  }

  const updatePagePosition = () => {
      // calculate new page position
      const pagePosition = getPagePosition()
      if(pagePosition !== null) {
        // handle view tracker, check if page in view changed
        const prevActivePage = pdfMetaStore.getPageInView(storeId)
        const activePage = Math.round(pagePosition) + 1
        // page has changed
        if(prevActivePage !== activePage) {
          // fix that last page will not be recognized in view when zoom level is low
          recognizeLastPageView(prevActivePage, activePage)
          // update view tracker
          podStore.setView(true, 'pdfPage', pdfId, activePage)
          podStore.setView(false, 'pdfPage', pdfId, prevActivePage)
        }
        // update page position in store
        pdfMetaStore.setPagePosition(storeId, pagePosition)
      }
  }

  const getPagePosition = () => {
    let pagePosition = null
    if(pageListRef.current && pageListRef.current.props && pageListRef.current.state && Array.isArray(pageHeights)){
      // get the current scroll offset from the list of pdf pages
      var pageOffset = pageListRef.current.state.scrollOffset
      // to calculate the current page in view,
      // subtract each page height from the scroll offset until page the is reached
      let pageIndex = 0
      for(const height of pageHeights) {
        pageOffset -= height
        if(pageOffset < 0) {
          const rest = 1-(Math.abs(pageOffset)/height)
          pagePosition = pageIndex+rest
          // round to two decimal places
          pagePosition = Math.round(pagePosition*100)/100
          break
        }
        pageIndex++
      }
    }
    return pagePosition
  }

  const recognizeLastPageView = (prevActivePage: number, activePage: number) => {
    if(pageListRef && pageListRef.current && pageListRef.current.props && Array.isArray(pageHeights)){
      // get height of pdf view window
      const listHeight = pageListRef.current.props.height
      const prevPageHeight =  Math.round(getPageHeight(prevActivePage-1))
      const currentPageHeight =  Math.round(getPageHeight(activePage-1))
      // which page will follow (up or down)
      const nextPage = (prevActivePage < activePage) ? activePage+1 : activePage-1
      let nextPageHeight = 0
      // there is no next page height after pdf ends and before pdf starts
      if((nextPage-1) > pageHeights.length || nextPage < 0) {
        nextPageHeight =  Math.round(getPageHeight(nextPage-1))
      }
      // next page is the last page
      if(nextPage === pageHeights.length) {
        // will the current page be scrollable past the halfway point
        // to trigger a page break event for the next page
        // also consider padding of the next page
        if((listHeight-nextPageHeight-30) > currentPageHeight/2) {
          podStore.setView(true, 'pdfPage', pdfId, nextPage)
        }
      }
      // remove view from last page if it was set before
      // check for it on page break for second last page on scrolling up
      // (page break for last page could not be reached before)
      if(prevActivePage === (pageHeights.length-1) && (activePage < prevActivePage)) {
        const lastPageHeight = Math.round(getPageHeight(pageHeights.length-1))
        if((listHeight-lastPageHeight-30) > prevPageHeight/2) {
          podStore.setView(false, 'pdfPage', pdfId, pageHeights.length)
        }
      }

    }
  }

  const handleRefChange = (ref: any) => {
    // update pdf container reference
    pageListRef.current = ref

    // handle scrolling:
    // if there is an interaction, scroll to it
    // else check if there is a navigation with scroll params, then scroll to page
    // otherwise scroll to last stored pdf position
    const scrollToPage = uiStore.scrollToPage
    if(params.interactionId || interactionEditId) {
      scrollToInteraction(ref)
    }
    else if(scrollToPage !== null) {
      if(pageListRef.current) {
        pageListRef.current.scrollToItem(scrollToPage-1, "start")
        // wait until rerendering is finished
        setTimeout(() => {
          uiStore.doneScrollToPage()
        }, 1000)
      }
    }
    else if(ref) {
      // calculate scroll compensation for zooming in and out
      const scrollCompensation = getScrollCompensation()

      // handle y-scroll
      const scrollTop = pdfMetaStore.getScrollTop(storeId)
      const scrollTopOffset =  scrollTop + scrollCompensation.y
      ref.scrollTo(scrollTopOffset)

      // handle scoll-x
      if(outerPageListRef.current) {
        // get stored scrollLeft position
        const scrollLeft = pdfMetaStore.getScrollLeft(storeId)
        const scrollLeftOffset = scrollLeft + scrollCompensation.x
        outerPageListRef.current.scrollLeft = scrollLeftOffset
        // update scrollLeft with actual position if there was compensation
        if(scrollCompensation.x !== 0) {
          pdfMetaStore.setScrollLeft(storeId, outerPageListRef.current.scrollLeft)
        }
      }

      const activePage = pdfMetaStore.getPageInView(storeId)
      // scroll thumbnail list to acitve page
      if(thumbListRef && thumbListRef.current) {
        thumbListRef.current.scrollToItem(activePage, "start")
      }

      // start view tracker on first pdf load
      podStore.setView(true, 'pdfPage', pdfId, activePage)
    }

    // add listener for future scrollLeft events
    // fix bug: sometimes rerender cause to set scrollLeft to 0 when loading pdf
    setTimeout(() => {
      addScrollLeftEventListener()
    }, 10)
  }

  const addScrollLeftEventListener = () => {
    const scrollContainer = outerPageListRef.current
    if(scrollContainer) {
      // check if there is already an event listener
      if (!scrollContainer.hasAttribute('scroll-listener')) {
        // update scroll left position in pdf store
        scrollContainer.addEventListener("scroll", handleScrollLeft)
      }
    }
  }

  const handleScrollLeft = () => {
    const scrollContainer = outerPageListRef.current
    if(scrollContainer) {
      // the scroll event is triggered with every rerender,
      // prevent setting scrollLeft to zero by setting the 'scroll-listener' attribute on second scroll event
      if (scrollContainer.hasAttribute('scroll-listener')) {
        pdfMetaStore.setScrollLeft(storeId, scrollContainer.scrollLeft)
      } else {
        scrollContainer.setAttribute('scroll-listener', 'true')
      }
    }
  }

  const scrollToInteraction = (ref: any) => {
    const pagePosition = getPagePosition()
    // get anchor from interaction
    let anchor = null
    // if pdf is opened to add new link
    if(interactionEditId === "addLink") {
      // fix bug: always update page pos
      if(pagePosition !== null) pdfMetaStore.setPagePosition(storeId, pagePosition)
      // opening the pdf for a new link does not require scrolling
      return
    }
    // if interaction is edited
    if(interactionEditId && interactionEditId !== "addLink") {
      const interactionEditAnchor = podStore.getInteractionEditAnchor(interactionEditId)
      if(interactionEditAnchor) anchor = interactionEditAnchor
    }
    // if jumping to an interaction
    if(anchor === null && params.interactionId) {
      const interaction = pod.getInteraction(params.interactionId)
      if(interaction) anchor = interaction.anchor
    }

    // scroll to the first rectangle of the interaction anchor
    if(anchor !== null) {
      const rect = anchor.rects[0]
      const interactionPage = rect.p - 1
      const interactionX = anchor.rects[0].x*scale
      const interactionY = anchor.rects[0].y*scale

      // calculate scrollTop
      let scrollTop = 0
      const viewWindowHeight = (ref && ref.props && ref.props.height) ? ref.props.height : null
      const pageHeights = pdfMetaStore.getPageHeights(storeId)
      if(Array.isArray(pageHeights) && pageHeights.length) {
        pageHeights.forEach((pageHeight,index) => {
          if(index < interactionPage) {
            scrollTop += pageHeight
          } else if(index === interactionPage) {
            // add remaining page y from interaction
            scrollTop += interactionY
            // show interaction in the upper third of the screen
            if(viewWindowHeight) scrollTop -= (viewWindowHeight/3)
            // prevent negative scrolling
            if(scrollTop <= 0) {
              scrollTop = 0
              // fix bug: if there is no scrolling,
              // the values in the store are not updated, in this case update them manually
              if(pagePosition !== null) pdfMetaStore.setPagePosition(storeId, pagePosition)
            }
            scrollTop = Math.round(scrollTop*100)/100
          }
        })
      }

      // y scroll to interaction
      if(ref && scrollTop) ref.scrollTo(scrollTop)
      // x scroll to interaction
      if(outerPageListRef.current) {
        outerPageListRef.current.scrollLeft = interactionX
      }
    }
  }

  const getScrollCompensation = () => {
    // compensate for the shift of the zoom point when scaling with a scrolling motion
    let compensationX = 0
    let compensationY = 0
    // ref to page container, same as pageListRef but this has its own functions
    const list = outerPageListRef.current
    // position from where the zoom takes place
    const scaleAnchor = pdfMetaStore.getScaleAnchor(storeId)
    // scrolling can be faster than rendering the view
    // get last time the scroll was compensated
    const lastCompensatedScale = pdfMetaStore.getLastCompensatedScale(storeId)
    if(list && lastCompensatedScale && scaleAnchor && lastCompensatedScale !== scale) {
      let scaleCorrectionFactor = (scale / lastCompensatedScale) - 1
      let rect                  = list.getBoundingClientRect()
      // get relative position of the scroll anchor
      let dx                    = scaleAnchor.x - rect.left
      let dy                    = scaleAnchor.y - rect.top
      // calculate x compensation if x-scrollbar is actual visible
      if(list.scrollWidth > list.clientWidth) {
        compensationX =  dx * scaleCorrectionFactor
        compensationX = Math.round((compensationX)*100)/100
      }
      // calculate y compensation
      compensationY =  dy * scaleCorrectionFactor
      compensationY = Math.round((compensationY)*100)/100
      // save that a compensation has taken place
      pdfMetaStore.setLastCompensatedScale(storeId, scale)
    }
    return {x: compensationX, y: compensationY}
  }

  const SamePageSize = (props: any) => (
    <AutoSizer>
      {({ height, width }:{ height:number, width:number }) => (
        <FixedSizeList
          height={height}
          itemCount={numPages}
          itemSize={getPageHeight(0)}
          onScroll={updatePagePosition}
          outerRef={outerPageListRef}
          overscanCount={2}
          ref={handleRefChange}
          useIsScrolling {...props}
          width={width}
        >
          {Segment}
        </FixedSizeList>
      )}
    </AutoSizer>
  )

  const VariablePageSize = (props: any) => (
    <AutoSizer>
      {({ height, width }:{ height:number, width:number }) => (
        <VariableSizeList
          estimatedItemSize={height}
          height={height}
          itemCount={numPages}
          itemSize={getPageHeight}
          onScroll={updatePagePosition}
          outerRef={outerPageListRef}
          overscanCount={2}
          ref={handleRefChange}
          useIsScrolling {...props}
          width={width}
        >
          {Segment}
        </VariableSizeList>
      )}
    </AutoSizer>
  )

  const SegmentContainer = () => {
    if(hasVariableSize) return <VariablePageSize />
    else return <SamePageSize />
  }

  const errorPage = <Box style={{
    height:'100dvh',
    display:'grid',
    overflowY: 'auto'
  }}><Infobox>
    {t("Could not load File")}
    <div><small>{loadingError}</small></div>
    <Button variant="outlined" onClick={() => { navigate(`/pod/${podId}/contents`) }}>{t('Back to Pod Contents')}</Button>
    <Button sx={{ml:1}} variant="contained" onClick={() => {console.log(`viewerupdate: ${uiStore.forceViewerUpdateCounter}`); uiStore.forceViewerUpdate(); console.log('done')}}>{t('Retry')}</Button>
  </Infobox></Box>


  return (
    <div id={`${storeId}-pdf-viewer`} style={{display: "grid"}}>
      <Document
          className={"whiteSmoke"}
          file={pdfUrl}
          loading={<LoadingPdf />}
          error={errorPage}
          onLoadError={(error) => {
            if (error.message) setLoadingError(error.message)
            console.warn("onLoadError:",error)
          }}
          // onLoadProgress={ (e) => { console.log('documentload', e);  } }
          onLoadSuccess={onDocumentLoadSuccess}
          onSourceError={(error) => {
            if (error.message) setLoadingError(error.message)
            console.log('Error while retrieving document source!', error.message)
          }}
          options={DocumentOptions()}
          renderMode={'canvas'}
          >
            {(Array.isArray(pageHeights) && pageHeights.length > 0) && (
                <SegmentContainer />
            )}
      </Document>
    </div>
  )
}

export default observer(Pdf)

// define react-pdf options outside of React component with useMemo to prevent reloading
const DocumentOptions = () => {
  const { sessionStore } = useStore()
  const sessionId = sessionStore.session.sessionId
  const documentOptions = useMemo(() => {
    const options = {
      cMapPacked: true,
      httpHeaders: {"X-SHRIMP-ID": sessionId}
    }
    return options
  }, [sessionId])
  return documentOptions
}
