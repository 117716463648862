import dayjs from "dayjs"
import { useEffect } from "react"
import { useStore } from "../../contexts/store"
import { useParams } from "../../stores/uiStore"
import { Rect as RectModel, interactionAnchor } from '../../shared/src/types/Interaction'
import { Op_addAnnotation } from "../../shared/src/types/Ops"

const rectIntersect = (r1:any, r2:any) => {

  const a1x = r1.left,
        a1y = r1.top,
        a2x = r1.left + r1.width,
        a2y = r1.top + r1.height,

        b1x = r2.left,
        b1y = r2.top,
        b2x = r2.left + r2.width,
        b2y = r2.top + r2.height

  const x_overlap = Math.max(0, Math.min(a2x,b2x) - Math.max(a1x,b1x));
  const y_overlap = Math.max(0, Math.min(a2y,b2y) - Math.max(a1y,b1y));

  const intersect_x = Math.max(a1x, b1x);
  const intersect_y = Math.max(a1y, b1y);

  return {
      left:intersect_x,
      top:intersect_y,
      width: x_overlap,
      height: y_overlap,
      squarepix: x_overlap * y_overlap
  }

}

const PageClickEvents = ({
    currentScale,
    interactionEditId,
    pageNumber,
    pdfId,
    storeId
  }: {
    currentScale: number,
    interactionEditId: string | undefined,
    pageNumber: number,
    pdfId: string,
    storeId: string
  }) => {
  const { pdfMetaStore, podStore, uiStore, opStore,sessionStore } = useStore()
  const { session } = sessionStore
  const { podId } = useParams()

  let clickRange:any
  let pointerDevice:string

  const getPageFromY = (y:number) => {
    const heights = pdfMetaStore.getPageHeights(storeId)
    var pageStart = 0
    var page = null
    var relPos = 0
    if (heights) heights.find((height, i) => {
      if ((pageStart < y) && (pageStart+height > y)) {
        page=i+1
        relPos = y - pageStart - 14
        return true
      }
      pageStart += height
      return false
    })
    return [page, relPos]
  }

  useEffect(() => {

    const handleClick = (e:any) => {
      // how long was the duration of the click
      const clickDuration = uiStore.selectionClickstart ? (Date.now() - uiStore.selectionClickstart) : 0

      if (podId && pdfId) {
        // long duration = add a new selection
        if (clickDuration>300 && podStore.pod?.isAllowed('addAnnotation', pdfId)) {
          // differentiate between freehand and text selection
          if (uiStore.isFreehandSelection) {
            addFreehandAnnotation()
          } else if(uiStore.selectionStartRange && uiStore.selectionEndRange) {
            addInteraction()
          }
        }
        // in interactionEdit mode no selections of interactions are allowed
        else if(!interactionEditId) {
          if ((clickDuration<200)) selectInteraction(e.clientX, e.clientY)
        }
      }

      uiStore.setSelectionStartRange(null)
      uiStore.setSelectionEndRange(null)
      uiStore.setSelectionClickstart(null)
      // set freehandbox to null
      uiStore.setFreehandBox()
      setRange(null, null)
    }

    const selectInteraction = (clickX: number, clickY: number) => {
      // determine selected pdf page, calculate coordinates within the pdf page
      const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1]?.getBoundingClientRect()
      if(pageNumber !== null && pageElement) {
        const currentScale = pdfMetaStore.getScale(storeId)
        // x position in relation to page element
        let x = clickX - pageElement.x
        // normalized x position
        x = parseToFixed(x / currentScale)
        // y position in relation to page element
        let y = clickY - pageElement.y
        // normalized y position
        y = parseToFixed(y / currentScale)

        const selectedInteractions = podStore.pod?.getInteractionsByCoordinates(pdfId, x, y, pageNumber+1, currentScale, pageElement)
        if(selectedInteractions){
          // remove doubles from selected interactions
          const selectedInteractionIds: Array<string> = []
          const uniqueSelectedInteractions = []
          for(const selected of selectedInteractions) {
            const interactionId = selected.interaction.interactionId
            if(interactionId && !selectedInteractionIds.includes(interactionId)) {
              uniqueSelectedInteractions.push(selected)
              selectedInteractionIds.push(interactionId)
            }
          }
          // open interaction or interaction swiper
          if(uniqueSelectedInteractions.length === 1) uiStore.selectInteraction(uniqueSelectedInteractions[0], podId, pdfId)
          else uiStore.openOverlappingInteractionMenu(uniqueSelectedInteractions)
        }
      }
    }

    const addFreehandAnnotation = () => {
      if (!uiStore.freehandBox) return

      const color = uiStore.getInteractionColor('addAnnotation')

      const rect = {
        x: parseToFixed((Math.min(uiStore.freehandBox.x0, uiStore.freehandBox.x1)) / currentScale),
        y: parseToFixed((Math.min(uiStore.freehandBox.y0, uiStore.freehandBox.y1)) / currentScale),
        w: parseToFixed(Math.abs(uiStore.freehandBox.x0 - uiStore.freehandBox.x1) / currentScale),
        h: parseToFixed(Math.abs(uiStore.freehandBox.y0 - uiStore.freehandBox.y1) / currentScale),
        p: uiStore.freehandBox.page
      }

      const anchor: interactionAnchor = {
        nodeId: pdfId,
        relText: '',
        rects:[rect],
        tool: 'box'
      }

      const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1]?.children[1]
      const pageRect = pageElement?.getBoundingClientRect()
      if (pageElement && pageRect) {
        const r1 = {
          left: pageRect.left + Math.min(uiStore.freehandBox.x0, uiStore.freehandBox.x1),
          top: pageRect.top + Math.min(uiStore.freehandBox.y0, uiStore.freehandBox.y1),
          width: Math.abs(uiStore.freehandBox.x0 - uiStore.freehandBox.x1),
          height: Math.abs(uiStore.freehandBox.y0 - uiStore.freehandBox.y1)
        }

        for(var elm of pageElement.children){
          if (elm && elm.getBoundingClientRect) {
            const spanrect = elm.getBoundingClientRect()
            const r2 = {
              left: spanrect.left,
              top: spanrect.top,
              width: spanrect.width,
              height: spanrect.height
            }
            const intersect = rectIntersect(r1, r2)
            if (intersect.squarepix) {

              if (elm.textContent) for(var i=0; i<elm.textContent.length; i++) {
                const range = document.createRange()
                range.setStart(elm.childNodes[0], i)
                range.setEnd(elm.childNodes[0], i+1)
                const r3 = range.getBoundingClientRect()
                const i2 = rectIntersect(r1, r3)
                const char = i2.squarepix ?  elm.textContent.substring(i, i+1) : '+'

                // include all letters that are mostly inside the marker
                if (i2.squarepix > (r3.width * r3.height)*.9) anchor.relText = anchor.relText + char
              }
              anchor.relText = anchor.relText + ` `
            }
          }

        }
      }

      // if interactionEditId: save selection for edit Viewer or adding a link
      if(interactionEditId) {
        if(interactionEditId === "addLink") {
          uiStore.setSelectedLinkAnchor(anchor)
        } else {
          podStore.setInteractionEditAnchor(interactionEditId, anchor)
        }
      }
      // else save selected rects as operation
      else {
        const op:Op_addAnnotation = {
          op: 'addAnnotation',
          podId: podId,
          data: {
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
            interactionType: 'annotation',
            interactionId: sessionStore.createUuid(),
            userId: session.user.userId,
            userName: podStore.userPseudonym,
            anchor: anchor,
            style: { color },
            label: "",
            reactions: {},
            tCreated: dayjs().unix(),
            tModified: dayjs().unix(),
            coid: null,
          }
        }
        if ((rect.w>4) && (rect.h>4)) opStore.doOp(op)
      }
    }

    const addInteraction = () => {
      if ((uiStore.selectionStartRange) && (uiStore.selectionEndRange)) setRange(uiStore.selectionStartRange, uiStore.selectionEndRange); else console.log('no uistore range')
      // get selection from mouse movement
      let sel = null
      if (window) sel = window.getSelection()
      // get position of pdf viewer component
      const pdfViewer = document.getElementById(`${storeId}-pdf-viewer`)?.getBoundingClientRect()
      // get scroll top position
      const scrollpos = pdfMetaStore.getScrollTop(storeId)

      if (sel && sel.rangeCount && pdfViewer && scrollpos!==null) {
        // text inside selection
        const text = sel.toString()
        // get rects of selection
        const range   = sel.getRangeAt(0)
        const rectsArray   = range.getClientRects()
        // for each rectangle within a selection
        if(rectsArray.length) {
          const rects = []
          for(let i=0; i<rectsArray.length; i++) {
            const rect = rectsArray[i]
            //console.log(`consider rect ${i}`, rect)
            // determine selected pdf page, calculate coordinates within the pdf page
            const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1]?.getBoundingClientRect()
            if(pageElement && pageElement.x && pageElement.y && pageElement.height && pageElement.width) {
              // sort out incorrect selections that include the entire pdf page and beyond.
              if(rect.width < pageElement.width && rect.height < pageElement.height && rect.y > 0) {
                const x = rect.x - pageElement.x
                // const y = rect.y - pageElement.y
                const [p, relY] = getPageFromY(rect.y + scrollpos - pdfViewer.top)

                // normalize with the scaling of the pdf
                // parse overlay coordinates to three decimal places
                // start with page number 1
                if (p && relY) {
                  const position: RectModel = {
                    x: parseToFixed(x / currentScale),
                    y: parseToFixed(relY / currentScale),
                    h: parseToFixed(rect.height / currentScale),
                    w: parseToFixed(rect.width / currentScale),
                    p: p
                  }
                  // do not consider zero heights or widths or NaN pageNumber
                  if(position.h > 1 && position.w > 1 && !Number.isNaN(position.p)) {
                    // do not accept rects outside the page
                    if((position.x + position.w) < (pageElement.width / currentScale) && (position.y + position.h) < (pageElement.height / currentScale)) {
                      rects.push(position)
                    }
                  }
                }
              }
            }
          }

          // remove text selection from mouse
          sel.removeAllRanges()

          // only allow to create annotations
          const interactionType = "annotation"
          const opCode = "addAnnotation"
          // get overlay color selected in the sidebar
          const color = uiStore.getInteractionColor(opCode)
          // optimizes the number of rectangles
          const optimizedRects = optimizeRects(rects)

          if(optimizedRects.length) {
            const anchor: interactionAnchor = {
              nodeId: pdfId,
              relText: text,
              rects: optimizedRects,
              tool: 'marker'
            }
            // if interactionEditId: save selection for edit Viewer or adding a link
            if(interactionEditId) {
              if(interactionEditId === "addLink") {
                uiStore.setSelectedLinkAnchor(anchor)
              } else {
                podStore.setInteractionEditAnchor(interactionEditId, anchor)
              }
            }
            // save selected rects as annotation
            else {
              const op:Op_addAnnotation = {
                op: 'addAnnotation',
                podId: podId,
                data: {
                  usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
                  interactionType: interactionType,
                  interactionId: sessionStore.createUuid(),
                  userId: session.user.userId,
                  userName: podStore.userPseudonym,
                  anchor: anchor,
                  style: { color },
                  label: "",
                  reactions: {},
                  tCreated: dayjs().unix(),
                  tModified: dayjs().unix(),
                  coid: null,
                }
              }
              opStore.doOp(op)
            }
          }
        }

      }
    }

    // calculate start and end of click to distinguish between short selection or selection of text
    const handleMouseDown = (e:MouseEvent) => {
      uiStore.setSelectionClickstart(Date.now())
      if (uiStore.isFreehandSelection) {
        const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1]?.getBoundingClientRect()
        if (pageElement) uiStore.setFreehandBox(pageNumber+1, e.clientX - pageElement.left, e.clientY - pageElement.top, e.clientX - pageElement.left, e.clientY - pageElement.top)
      } else {
        const selectionStartRange = getCaretRangeAtXY(e.clientX, e.clientY)
        if (selectionStartRange) {
          uiStore.setSelectionStartRange(selectionStartRange)
          e.preventDefault()
        }
      }
    }

    const handleTouchStart = (e:TouchEvent) => {
      uiStore.setSelectionClickstart(Date.now())
      if (e.touches.length === 1) {
        if ((pointerDevice === 'pen') && (uiStore.isFreehandSelection)) {
          const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1]?.getBoundingClientRect()
          if (pageElement) uiStore.setFreehandBox(pageNumber+1, e.touches[0].clientX - pageElement.left, e.touches[0].clientY - pageElement.top, e.touches[0].clientX - pageElement.left, e.touches[0].clientY - pageElement.top)
          e.preventDefault()
        }
        else {
          const selectionStartRange = getCaretRangeAtXY(e.touches[0].clientX, e.touches[0].clientY)
          if (selectionStartRange) {
            if (
              (pointerDevice === 'pen') || (
                (clickRange && selectionStartRange) &&
                (Math.abs(clickRange.clientX - selectionStartRange.clientX)<8) &&
                (Math.abs(clickRange.clientY - selectionStartRange.clientY)<8) &&
                ((selectionStartRange.t0 - clickRange.t0)<200)
              )
            ) {
              uiStore.setSelectionStartRange(selectionStartRange)
              e.preventDefault()
            }
            else {
              clickRange = selectionStartRange
            }
          }
        }
      }
    }

    const handleMouseMove = (e:MouseEvent) => {
      if (uiStore.freehandBox && (e.buttons===1)) {
        if (uiStore.isFreehandSelection) {
          window?.getSelection()?.empty()
          //uiStore.setFreehandBox(pageNumber+1, uiStore.freehandBox.x0, uiStore.freehandBox.y0, e.clientX, e.clientY)
          const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1]?.getBoundingClientRect()
          if (pageElement) uiStore.setFreehandBox(pageNumber+1, uiStore.freehandBox.x0, uiStore.freehandBox.y0, e.clientX- pageElement.left, e.clientY- pageElement.top)
          return
        }
        else {
          uiStore.setFreehandBox()
        }
      }
      if ((uiStore.selectionStartRange) && (e.buttons===1)) {
        // const selection:Selection|null = getSelection()
        const selectionEndRange = getCaretRangeAtXY(e.clientX, e.clientY)
        if (selectionEndRange) {
          uiStore.setSelectionEndRange(selectionEndRange)
        }
        if (uiStore.selectionEndRange) setRange(uiStore.selectionStartRange, uiStore.selectionEndRange)
        e.preventDefault()
      }
    }

    const handleTouchMove = (e:TouchEvent) => {
      if (uiStore.freehandBox && (e.touches.length === 1)) {
        if (uiStore.isFreehandSelection) {
          window?.getSelection()?.empty()
          const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1]?.getBoundingClientRect()
          if (pageElement) uiStore.setFreehandBox(pageNumber+1, uiStore.freehandBox.x0, uiStore.freehandBox.y0, e.touches[0].clientX- pageElement.left, e.touches[0].clientY- pageElement.top)
          return
        }
        else {
          uiStore.setFreehandBox()
        }
      }

      if ((uiStore.selectionStartRange) && (e.touches.length === 1)) {
        // const selection:Selection|null = getSelection()
        const selectionEndRange = getCaretRangeAtXY(e.touches[0].clientX, e.touches[0].clientY)
        if (selectionEndRange) {
          uiStore.setSelectionEndRange(selectionEndRange)
        }
        if (uiStore.selectionEndRange) setRange(uiStore.selectionStartRange, uiStore.selectionEndRange)
        e.preventDefault()
      }
    }

    const handleTouchEnd = (e:TouchEvent) => {
      handleClick({ clientX: e.changedTouches[0].clientX, clientY: e.changedTouches[0].clientY })
      if (e.cancelable) e.preventDefault()
    }

    const handlePointer = (e:PointerEvent) => {
      pointerDevice = e.pointerType
      // workaround for Firefox Mobile
      if ((e.pointerType === 'touch') && (e.width === 0)) pointerDevice = 'pen'
    }

    // register event listener on mount
    const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1] as HTMLElement
    if (pageElement) {
      pageElement.addEventListener('mousedown', handleMouseDown)
      pageElement.addEventListener('mousemove', handleMouseMove)
      pageElement.addEventListener('mouseup', handleClick)
      pageElement.addEventListener('touchstart', handleTouchStart)
      pageElement.addEventListener('touchmove', handleTouchMove)
      pageElement.addEventListener('touchend', handleTouchEnd)
      if (window.PointerEvent) pageElement.addEventListener('pointerdown', handlePointer)
    }



    return () => {
      // unregister event listener on unmount
      const pageElement = document.getElementById(`${storeId}-page-${pageNumber}`)?.children[1] as HTMLElement
      if (pageElement) {
        pageElement.removeEventListener('mousedown', handleMouseDown)
        pageElement.removeEventListener('mousemove', handleMouseMove)
        pageElement.removeEventListener('mouseup', handleClick)
        pageElement.removeEventListener('touchstart', handleTouchStart)
        pageElement.removeEventListener('touchmove', handleTouchMove)
        pageElement.removeEventListener('touchend', handleTouchEnd)
        if (window.PointerEvent) pageElement.removeEventListener('pointerdown', handlePointer)
      }
    }
  }, [storeId])

  const getCaretRangeAtXY = (x:number, y:number) => {
    var range: {
      node: Node,
      offset: number,
      t0: number,
      pageNumber: number,
      clientX: number,
      clientY: number,
    } | null = null

    if (typeof document.createRange !== "undefined") {

      // @ts-ignore
      if (document.caretPositionFromPoint) {
        // @ts-ignore
        const r:any = document.caretPositionFromPoint(x, y);
        range = {
          node: r.offsetNode,
          offset: r.offset,
          t0: Date.now(),
          pageNumber: r.offsetNode.parentElement?.closest('.react-pdf__Page')?.getAttribute('data-page-number'),
          clientX: x,
          clientY: y,
        }
      } else if (document.caretRangeFromPoint) {
        const r: any = document.caretRangeFromPoint(x, y);
        range = {
          node: r.startContainer,
          offset: r.startOffset,
          t0: Date.now(),
          pageNumber: r.startContainer.parentElement?.closest('.react-pdf__Page')?.getAttribute('data-page-number'),
          clientX: x,
          clientY: y,
        }
      }
    }
    if ((!range) || (range.node.nodeType !== 3) || (range.node.nodeName !== '#text') || (range.node.parentNode?.nodeName !== 'SPAN')) return null
    return range;
  }

  const setRange = (startRange: {node: Node, offset: number} | null, endRange: {node: Node, offset: number} | null) => {
      const selection:Selection|null = getSelection()

      if (startRange && endRange) {
        const domOrder = startRange.node.compareDocumentPosition(endRange.node)
        if (selection) {
          selection.removeAllRanges()
          var newRange = new Range()

          if (window.getSelection) {
            if (window.getSelection()?.empty) {  // Chrome
              window.getSelection()?.empty();
            } else if (window.getSelection()?.removeAllRanges) {  // Firefox
              window.getSelection()?.removeAllRanges();
            }
          }

          if ((domOrder === 4) || ((domOrder === 0) && (startRange.offset < endRange.offset))) {
            newRange.setStart(startRange.node, startRange.offset)
            newRange.setEnd(endRange.node, endRange.offset)
          }
          else if ((domOrder === 2) || ((domOrder === 0) && (startRange.offset > endRange.offset))) {
            newRange.setStart(endRange.node, endRange.offset)
            newRange.setEnd(startRange.node, startRange.offset)
          }

          if (newRange) {
            selection.addRange(newRange)
            return true
          }
        }
      }
      else {
        if (selection) selection.removeAllRanges()
      }

      return null
  }

  return null
}


export default PageClickEvents


function parseToFixed(number: number) {
  return parseFloat(number.toFixed(3))
}


// adopted from shrimp-pods
function optimizeRects(rect: any, verbose = false) {

  const nrect = rect.map((r: any) => ({
    x1: r.x,
    y1: r.y,
    x2: r.x + r.w,
    y2: r.y + r.h,
    p: r.p
  }))

  if (verbose) console.log(nrect)

  const intersect = (rect1: any, rect2: any) => {
    return Math.max(0, Math.min(rect1.x2, rect2.x2) - Math.max(rect1.x1, rect2.x1)) * Math.max(0, Math.min(rect1.y2, rect2.y2) - Math.max(rect1.y1, rect2.y1))
  }

  const union = (rect1: any, rect2: any) => {
    return ((rect1.x2 - rect1.x1) * (rect1.y2 - rect1.y1)) + ((rect2.x2 - rect2.x1) * (rect2.y2 - rect2.y1)) - intersect(rect1, rect2)
  }

  const calc = (i0: any, i1: any) => {
    const nrect1 = nrect[i0]
    const nrect2 = nrect[i1]
    const si = intersect(nrect1, nrect2 )
    const su = union(nrect1, nrect2)
    if (verbose) console.log(si, su, 100 * si/su )
    return 100 * si/su
  }

  for(var i0=0; i0<nrect.length; i0++) {
    if (nrect[i0] === null) continue
    for(var i1=i0+1; i1<nrect.length; i1++) {
      if (nrect[i1] === null) continue
      const ratio = calc(i0, i1)
      if (verbose) console.log(`Compare ${i0} to ${i1}: `, ratio)
      if (ratio > 70) {
        if (verbose) console.log('merge')
        nrect[i0] = {
          x1: Math.min(nrect[i0].x1, nrect[i1].x1),
          y1: Math.min(nrect[i0].y1, nrect[i1].y1),
          x2: Math.max(nrect[i0].x2, nrect[i1].x2),
          y2: Math.max(nrect[i0].y2, nrect[i1].y2),
          p: nrect[i0].p
        }
        nrect[i1] = null
      }
    }
  }

  return nrect.filter((n: any) => n !== null).map((r: any) => ({
    x: r.x1,
    y: r.y1,
    w: r.x2 - r.x1,
    h: r.y2 - r.y1,
    p: r.p
  }))
}
