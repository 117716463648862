import { makeObservable, observable, action } from 'mobx'
import exportSessionStore from './sessionStore'
import api from '../api/api'
import dayjs from 'dayjs'

const timeout = 1000 * 60 * 15

export interface uploadStoreModel {
  uploadQueue:UploadJob[],
  add: (endpoint:string, podId:string, signedUrlAttributes:object, file:ArrayBuffer, filename:string, mimeType:string, successCallback:Function|null, failureCallback:Function|null) => void,
  status: 'idle'|'uploading'|'error',
}

export type UploadJob = {
  file: ArrayBuffer,
  filename: string,
  mimeType:string,
  signedUrl: string,
  rangeCompleted?:number,
  t0?:number,
  successCallback: Function|null,
  failureCallback: Function|null,
}

class uploadStore {

  uploadQueue:UploadJob[] = []
  status:'idle'|'uploading'|'error' = 'idle'

  constructor() {
    makeObservable(this, {
      uploadQueue: observable,
      status: observable,

      addToQueue: action,
      shiftFromQueue: action,
      setStatus: action,
      doUpload: action,
    })
  }

  setStatus(status:'idle'|'uploading'|'error') {
    // console.log(`set uploadStore.status = ${status}`)
    this.status = status
  }

  addToQueue(job:UploadJob) {
    this.uploadQueue.push(job)
  }

  shiftFromQueue() {
    const r = this.uploadQueue.shift()
    return r
  }

  async doUpload() {
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(`timeout of ${timeout/1000}s`), timeout)

    while(this.uploadQueue.length) {

      const entry = this.uploadQueue[0]

      if (!entry) {
        this.shiftFromQueue()
        continue
      }

      this.setStatus('uploading')

      // Start processing the entry
      entry.t0             = dayjs().unix()
      entry.rangeCompleted = 0

      const setRangeCompleted = (n:number) => { entry.rangeCompleted=n }

      try {

        // Do the upload
        const xhr = new XMLHttpRequest();
        const success = await new Promise((resolve) => {

          xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
              //console.log("upload progress:", event.loaded / event.total)
              setRangeCompleted(event.loaded)
            }
          })

          xhr.addEventListener("loadend", () => {
            console.log(`Upload completed`)
            resolve(xhr.readyState === 4 && xhr.status === 200)
          })

          xhr.open("PUT", entry.signedUrl, true)
          xhr.setRequestHeader("Content-Type", entry.mimeType)
          xhr.setRequestHeader("x-shrimp-bypass-sw", 'bypass')
          xhr.send(entry.file)
        })

        this.setStatus('idle')
        if (entry.successCallback) entry.successCallback()

      }
      catch (e) {
        this.setStatus('idle')
        console.error(e)
        this.shiftFromQueue()
        if (entry.failureCallback) entry.failureCallback()
      }
      finally {
        clearTimeout(id)
        this.setStatus('idle')
        this.shiftFromQueue()
      }
    } // end while()
  }

  async add(endpoint:string, podId:string, signedUrlAttributes:object, file:ArrayBuffer, filename:string, mimeType:string, successCallback:Function|null = null, failureCallback:Function|null=null) {

    const sessionId = exportSessionStore.session ? exportSessionStore.session.sessionId : null
    const res = await api.fetch(endpoint, {
      method:'POST',
      headers: {
        'X-SHRIMP-ID': sessionId,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        podId,
        mimeType,
        ...signedUrlAttributes,
      })
    })

    const { signedUrl } = res.body ? res.body : { signedUrl: null }

    if (signedUrl) {
      this.addToQueue({
        file,
        filename,
        mimeType,
        signedUrl,
        successCallback,
        failureCallback
      })
      if (this.status !== 'uploading') this.doUpload()
    }
    else {
      if (failureCallback) failureCallback()
    }
  }

}

const exportUploadStore = new uploadStore()
export default exportUploadStore